/**
 * Componente Swiper:
 * 
 * Acá se encuentra el carrusel de marcas.
 * 
 * @module ComponenteSwiperjsx
 */

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./swiperr.css";

let pics = [
    process.env.REACT_APP_FRONT_URL + "/uploads/assets/swiper/logo-gris-asus.png",
    process.env.REACT_APP_FRONT_URL + "/uploads/assets/swiper/logo-gris-brother.png",
    process.env.REACT_APP_FRONT_URL + "/uploads/assets/swiper/logo-gris-epson.png",
    process.env.REACT_APP_FRONT_URL + "/uploads/assets/swiper/logo-gris-lg.png",
    process.env.REACT_APP_FRONT_URL + "/uploads/assets/swiper/logo-gris-thomas.png"
];

function Swiperr() {
    return (
        <div>
            <div className="container">
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation={false}
                    spaceBetween={1}
                    loop={true}
                    slidesPerView={4}
                    slidesPerGroup={1}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        400: {
                            slidesPerView: 2,
                            spaceBetween: 5,
                        },
                        1000: {
                            slidesPerView: 4,
                            spaceBetween: 6,
                        },
                    }}
                >
                    {pics.map((p, i) => {
                        // Extraer el nombre del archivo desde la URL
                        const altText = p.split("/").pop()?.split(".")[0]?.replace(/-/g, " ") || "logo";
                        return (
                            <div key={i}>
                                <SwiperSlide key={i}>
                                    <div className="mt-5 mb-5">
                                        <img
                                            src={p}
                                            style={{
                                                objectFit: "contain",
                                                width: "180px",
                                                height: "60px",
                                            }}
                                            loading="lazy"
                                            alt={altText}
                                        />
                                    </div>
                                </SwiperSlide>
                            </div>
                        );
                    })}
                </Swiper>
                <div className="pagination"></div>
            </div>
        </div>
    );
}

export default Swiperr;
