/**
 * Página Campañas:
 *
 * acá se crea la campaña
 *
 * @module AdminCrearCampanas
 */

import { useEffect, useState } from "react";
import axios from "axios";
import { AdminSidebar } from "../../components";
import "../../components/Sidebars/adminsidebar.css";
import Mensaje from "../../components/Modales/Mensaje";
import Modal from "react-bootstrap/Modal";
import Marcaje from "./Marcaje";
import { useNavigate } from "react-router-dom";

const MAX_BANNER_SIZE_MB = 1; // Tamaño máximo en MB
const MAX_BANNER_WIDTH = 2065; // Ancho máximo en px
const MAX_BANNER_HEIGHT = 400; // Altura máxima en px

const Campanas = () => {
  // Nuevos estados para el banner y alt
  const [titleCount, setTitleCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [banner, setBanner] = useState(null); // Archivo del banner
  const [bannerAlt, setBannerAlt] = useState(""); // Texto alternativo para el banner
  const [accion, setAccion] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [nombre, setNombre] = useState("");
  const [previewBanner, setPreviewBanner] = useState(""); // URL para la vista previa
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaTermino, setFechaTermino] = useState("");
  const [bannerLink, setBannerLink] = useState(""); // Enlace relacionado al banner
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [producto, setProducto] = useState(null); // Producto encontrado por el SKU
  const [productos, setProductos] = useState([]); // Listado de productos
  const [campanas, setCampanas] = useState([]);
  const [showModal, setShowModal] = useState(false); // Controla la visibilidad del modal
  const [campanaSeleccionada, setCampanaSeleccionada] = useState(null); // Campaña seleccionada para cambiar estado
  const [busquedaCampana, setBusquedaCampana] = useState("");
  const [IdCampana, setIdCampana] = useState("");
  const [nombreCampana, setNombreCampana] = useState("");
  const [fechaCampana, setFechaCampana] = useState("");
  const accessToken = sessionStorage.getItem("access");
  const [existenDatos, setExistenDatos] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState("");
  const [isBuscarActivado, setIsBuscarActivado] = useState(false); // Estado para disparar búsqueda al hacer clic en "Buscar"
  const [busquedaActiva, setBusquedaActiva] = useState(false); // Estado para indicar que hay un filtro activo
  const [h1, setH1] = useState("")

  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 65) {
      setTitle(value);
      setTitleCount(value.length);
    }
  };
  
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 156) {
      setDescription(value);
      setDescriptionCount(value.length);
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];

    // Validar si se seleccionó un archivo
    if (!file) {
      setMensaje("Debe seleccionar un archivo para el banner-rojo");
      setBanner(null);
      setPreviewBanner(""); // Eliminar vista previa si no hay archivo
      return;
    }

    // Validar tamaño del archivo
    const fileSizeMB = file.size / 1024 / 1024;
    if (fileSizeMB > MAX_BANNER_SIZE_MB) {
      setMensaje(`El tamaño máximo del banner es de 1 MB-rojo`);
      setBanner(null);
      setPreviewBanner(""); // Eliminar vista previa si no es válido
      return;
    }

    // Validar dimensiones del banner
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;
      if (width > MAX_BANNER_WIDTH || height > MAX_BANNER_HEIGHT) {
        setMensaje(
          `Las dimensiones del banner deben ser ${MAX_BANNER_WIDTH} × ${MAX_BANNER_HEIGHT} px-rojo`
        );
        setBanner(null);
        setPreviewBanner(""); // Eliminar vista previa si no es válido
      } else {
        setBanner(file); // Guardar archivo si pasa validación
        setPreviewBanner(img.src); // Asignar URL de vista previa
        setMensaje("El banner se cargó correctamente-verde");
      }
    };
  };

  const validaDatosEnviar = () => {
    // Validar que todos los campos obligatorios estén completos
    if (!nombre.trim()) {
      setMensaje("Debe ingresar un nombre para la campaña-rojo");
      return false;
    }

    if (!fechaInicio || !fechaTermino) {
      setMensaje("Debe seleccionar las fechas de inicio y término-rojo");
      return false;
    }

    // Validar que la fecha de inicio sea menor que la de término
    if (new Date(fechaInicio) >= new Date(fechaTermino)) {
      setMensaje("La fecha de inicio debe ser menor que la de término-rojo");
      return false;
    }

    if (!title.trim()) {
      setMensaje("Debe ingresar un meta title-rojo");
      return false;
    }

    if (!description.trim()) {
      setMensaje("Debe ingresar una meta description-rojo");
      return false;
    }

    if (productos.length === 0) {
      setMensaje("Debe agregar al menos un producto a la campaña-rojo");
      return false;
    }

    if(!h1.trim()){
      setMensaje("Debe ingresar un texto en h1-rojo");
      return false;
    }

    // Validar banner (si fue cargado)
    if (banner) {
      const fileSizeMB = banner.size / 1024 / 1024; // Convertir a MB
      if (fileSizeMB > MAX_BANNER_SIZE_MB) {
        setMensaje("El tamaño máximo del banner es de 1 MB-rojo");
        return false;
      }

      const img = new Image();
      img.src = URL.createObjectURL(banner);
      img.onload = () => {
        if (img.width > MAX_BANNER_WIDTH || img.height > MAX_BANNER_HEIGHT) {
          setMensaje(
            `Las dimensiones del banner deben ser ${MAX_BANNER_WIDTH} × ${MAX_BANNER_HEIGHT} px-rojo`
          );
          return false;
        }
      };
    }

    // Validar el alt (opcional)
    if (bannerAlt.trim()) {
      if (bannerAlt.length < 3 || bannerAlt.length > 100) {
        setMensaje(
          "El texto alternativo debe tener entre 3 y 100 caracteres-rojo"
        );
        return false;
      }

      // Verificar que no contenga caracteres HTML
      const validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;
      if (validarHTML.test(bannerAlt)) {
        setMensaje("El texto alternativo no puede contener HTML-rojo");
        return false;
      }
    }

    // Validar que el enlace del banner, si se proporciona, sea una URL válida
    if (bannerLink.trim()) {
      const urlRegex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
      if (!urlRegex.test(bannerLink.trim())) {
        setMensaje("El enlace del banner no tiene un formato válido-rojo");
        return false;
      }
    }

    // Si todo es válido
    return true;
  };

  const normalizarFechaConSegundos = (fecha) => {
    if (!fecha) return "";

    const date = new Date(fecha);
    const anio = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
    const dia = String(date.getDate()).padStart(2, "0");
    const horas = String(date.getHours()).padStart(2, "0");
    const minutos = String(date.getMinutes()).padStart(2, "0");

    // Forzamos los segundos a "00"
    const segundos = "00";

    return `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validaDatosEnviar()) {
      setMensaje("Creando la campaña-alert");

      const fechaInicioNormalizada = normalizarFechaConSegundos(fechaInicio);
      const fechaTerminoNormalizada = normalizarFechaConSegundos(fechaTermino);

      const formData = new FormData();
      formData.append("nombre", nombre);
      formData.append("inicio", fechaInicioNormalizada); // Fecha de inicio con segundos
      formData.append("final", fechaTerminoNormalizada); // Fecha de término con segundos
      formData.append("productos", JSON.stringify(productos.map((p) => p.id))); // Serializar productos
      formData.append("title", title);
      formData.append("description", description);
      formData.append("tipo_id", 1);
      formData.append("h1", h1);

      if (banner) {
        formData.append("banner", banner);
        formData.append("alt", bannerAlt);
        formData.append("link", bannerLink.trim()); // Enviar el enlace
      }

      axios
        .post(
          process.env.REACT_APP_BACK_URL + `/api/campaigns/crear_campaign`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setMensaje(response.data + "-verde");
          setTimeout(() => {
            location.reload();
          }, 2500);
        })
        .catch((error) => {
          // Verificar si existe error.response y extraer el mensaje del backend
          if (error.response && error.response.data) {
            // Mostrar el mensaje del servidor
            setMensaje(error.response.data + "-rojo");
          } else {
            // Fallback para errores sin respuesta o problemas de red
            setMensaje("Ocurrió un error inesperado-rojo");
          }
        });
    }
  };

  const toUpperCase = (value) => {
    setSku(value.toUpperCase());
  };

  const validateBuscarSKU = () => {
    const validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (sku === "") {
      setMensaje("Debe ingresar el SKU-rojo");
      return false;
    }

    if (validarHTML.test(sku)) {
      setMensaje("Debe ingresar un SKU válido-rojo");
      return false;
    }

    return true;
  };

  const handleSkuCode = () => {
    if (validateBuscarSKU()) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL +
            `/api/admin/producto/get_producto_by_sku/${sku.toUpperCase()}`
        )
        .then((response) => {
          if (response.data.length === 0) {
            setMensaje("Producto no encontrado-rojo");
            setProducto(null);
          } else {
            setProducto(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddProducto = () => {
    if (producto) {
      // Verificar que el producto no esté ya en la lista
      if (productos.some((p) => p.id === producto.id)) {
        setMensaje("El producto ya está en la lista-rojo");
        return;
      }

      // Agregar el producto a la lista
      setProductos((prev) => [
        ...prev,
        {
          id: producto.id,
          sku: producto.sku,
          nombre: producto.nombre,
        },
      ]);
      setProducto(null); // Limpiar producto
      setSku(""); // Limpiar campo SKU
      setMensaje("Producto agregado a la lista-verde");
    }
  };

  const handleRemoveProducto = (id) => {
    // Eliminar el producto del listado por su ID
    setProductos((prev) => prev.filter((producto) => producto.id !== id));
    setMensaje("Producto eliminado de la lista-verde");
  };

  const listarCampanas = () => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/campaigns/listar_campaign?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setCampanas(response.data.campañas);
        setTotalPaginas(response.data.paginacion.total_paginas);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isBuscarActivado || busquedaActiva) {
      if (
        (busquedaCampana === 1 && IdCampana) || // Búsqueda por ID
        (busquedaCampana === 2 && fechaCampana) || // Búsqueda por Fecha
        (busquedaCampana === 3 && nombreCampana) // Búsqueda por Nombre
      ) {
        handleSubmitBuscarCampana(); // Ejecutar búsqueda
      } else {
        setMensaje("Faltan datos para realizar la búsqueda-rojo");
      }
      setIsBuscarActivado(false); // Reiniciar el estado de "Buscar"
    }
  }, [isBuscarActivado, currentPage]); // Escucha tanto cambios en el botón como en la página actual

  const handleBotonBuscar = () => {
    setIsBuscarActivado(true); // Activar la búsqueda
    setBusquedaActiva(true); // Indicar que la búsqueda está activa
  };

  useEffect(() => {
    if (accion === 3) {
      // Si la acción es listar campañas, ejecutar lista
      listarCampanas();
    }
  }, [accion, currentPage]); // Escuchar cambios en la acción y la página actual

  useEffect(() => {
    if (accion == 3) {
      listarCampanas();
    }
  }, [accion]);

  const handleVolver = () => {
    setAccion("");
    setCampanas("");
    setProducto("");
    setProductos([]);
    setSku("");
    setDescription("");
    setFechaInicio("");
    setFechaTermino("");
    setNombre("");
    setTitle("");
    setBusquedaCampana("");
    setIdCampana("");
    setFechaCampana("");
    setNombreCampana("");
  };

  const handleModificar = (id) => {
    navigate(`/admin/editar-campana?id=${id}`);
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);

    // Extraer componentes de la fecha
    const dia = String(date.getDate()).padStart(2, "0");
    const mes = String(date.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
    const anio = date.getFullYear();
    const horas = String(date.getHours()).padStart(2, "0");
    const minutos = String(date.getMinutes()).padStart(2, "0");
    const segundos = String(date.getSeconds()).padStart(2, "0");

    // Formatear como "dd/mm/yyyy hh:mm:ss"
    return `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;
  };

  const confirmarCambioEstado = () => {
    const accessToken = sessionStorage.getItem("access");

    if (!campanaSeleccionada) return;

    const url =
      campanaSeleccionada.activo == "1"
        ? "/api/campaigns/desactivar_campaign"
        : "/api/campaigns/activar_campaign";

    // Crear el objeto FormData
    const formData = new FormData();
    formData.append("id", campanaSeleccionada.id);

    axios
      .post(process.env.REACT_APP_BACK_URL + url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setMensaje(
          `La campaña ha sido ${
            campanaSeleccionada.activo == "1" ? "desactivada" : "activada"
          } correctamente-verde`
        );
        listarCampanas(); // Actualizar la lista de campañas
      })
      .catch((error) => {
        console.error(error);
        setMensaje("Error al cambiar el estado de la campaña-rojo");
      })
      .finally(() => {
        setShowModal(false); // Cerrar el modal
        setCampanaSeleccionada(null); // Limpiar la campaña seleccionada
      });
  };

  const handleEstadoCampana = (campana) => {
    setCampanaSeleccionada(campana); // Guardar la campaña seleccionada
    setShowModal(true); // Mostrar el modal
  };

  const validateBusquedaCampana = () => {
    var validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (busquedaCampana == 1) {
      if (IdCampana == "") {
        setMensaje("Debe ingresar el ID de la campaña-rojo");
        return false;
      }

      if (validarHTML.test(IdCampana)) {
        setMensaje("Debe ingresar un ID de campaña válido-rojo");
        return false;
      }
    } else if (busquedaCampana == 2) {
      if (fechaCampana == "") {
        setMensaje("Debe ingresar la fecha de la campaña-rojo");
        return false;
      }
    } else if (busquedaCampana == 3) {
      if (nombreCampana == "") {
        setMensaje("Debe ingresar el nombre de la campaña-rojo");
        return false;
      }

      if (validarHTML.test(nombreCampana)) {
        setMensaje("Debe ingresar un nombre de campaña válido-rojo");
        return false;
      }
    }

    return true;
  };

  const handleSubmitBuscarCampana = () => {
    let busqueda = "";

    // Construir la URL de búsqueda según el filtro
    if (busquedaCampana === 1 && IdCampana) {
      busqueda = `?id=${IdCampana}&page=${currentPage}`;
    } else if (busquedaCampana === 2 && fechaCampana) {
      busqueda = `?fecha=${fechaCampana}&page=${currentPage}`;
    } else if (busquedaCampana === 3 && nombreCampana) {
      busqueda = `?nombre=${nombreCampana}&page=${currentPage}`;
    } else {
      setMensaje("Faltan datos para realizar la búsqueda-rojo");
      return;
    }

    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/campaigns/buscar_campaign` +
          busqueda,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        if (response.data.campañas.length > 0) {
          setExistenDatos(true);
          setCampanas(response.data.campañas);
          setTotalPaginas(response.data.paginacion.total_paginas); // Configurar total de páginas
        } else {
          setExistenDatos(false);
        }
      })
      .catch((error) => {
        setExistenDatos(false);
        console.error("Error al buscar campaña:", error);
      });
  };

  return (
    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />

      <div className="regular">
        <h1>
          Dreamtec - <span>Menú Campañas</span>
        </h1>

        <div className="shadow">
          <div className="row justify-content-center">
            {!accion && (
              <>
                <div className="col-12 text-center mt-3 mb-5">
                  <h2>Seleccione una opción para desplegar el contenido</h2>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                  <button
                    className="agregar"
                    onClick={() => {
                      setAccion(1);
                    }}
                  >
                    <i className="fa-solid fa-circle-plus"></i> &nbsp; Crear
                    Campaña
                  </button>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                  <button
                    className="agregar"
                    onClick={() => {
                      setAccion(2);
                    }}
                  >
                    <i className="fa-solid fa-circle-plus"></i> &nbsp; Buscar
                    Campaña
                  </button>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                  <button
                    className="agregar"
                    onClick={() => {
                      setAccion(3);
                    }}
                  >
                    <i className="fa-solid fa-circle-plus"></i> &nbsp;Listar las
                    campañas
                  </button>
                </div>
              </>
            )}
            {accion == 1 && (
              <>
                <div className="col-12 text-start mt-3 mb-3">
                  <h2>Crear campaña</h2>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Nombre: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        className="form-control"
                        required
                        placeholder="Ingresa nombre"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Fecha inicio: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                        className="form-control"
                        required
                        placeholder="Ingresa la fecha de inicio"
                      />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Fecha termino: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={fechaTermino}
                        onChange={(e) => setFechaTermino(e.target.value)}
                        className="form-control"
                        required
                        placeholder="Ingresa la fecha de termino"
                      />
                    </div>
                  </div>

                  <div className="row">
                    {/* Input para cargar el banner */}
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Banner (opcional): &nbsp;
                        <span className="text-muted">
                          Tamaño máx: 1MB | {MAX_BANNER_WIDTH} ×{" "}
                          {MAX_BANNER_HEIGHT}px
                        </span>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleBannerChange}
                        className="form-control"
                      />
                    </div>

                    {/* Input para el texto alternativo del banner */}
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>Alt (Texto alternativo, opcional): &nbsp;</label>
                      <input
                        type="text"
                        value={bannerAlt}
                        onChange={(e) => setBannerAlt(e.target.value)}
                        className="form-control"
                        placeholder="Ej: Banner promocional"
                      />
                    </div>
                  </div>

                  {/* URL del banner */}
                  <div className="col-xxl-4 col-lg-6 col-md-12 mb-4">
                    <label>Enlace del banner (opcional):</label>
                    <input
                      type="text"
                      value={bannerLink}
                      onChange={(e) => setBannerLink(e.target.value)}
                      className="form-control"
                      placeholder="https://ejemplo.com"
                    />
                  </div>

                  {/* Vista previa del banner */}
                  {previewBanner && (
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-md-12 mb-4">
                      <label>Vista previa:</label>
                      <img
                        src={previewBanner}
                        alt="Vista previa del banner"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "150px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>SEO</h2>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Meta Title: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={title}
                        onChange={(e) => handleTitleChange(e)}
                        className="form-control"
                        required
                        placeholder="Ingresa el meta title"
                      />
                      <small className={titleCount >= 60 ? "text-danger" : "text-muted"}>
                        {titleCount}/65 caracteres
                      </small>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        Meta Description: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        value={description}
                        onChange={(e) => handleDescriptionChange(e)}
                        className="form-control"
                        required
                        placeholder="Ingresa el meta description"
                        rows="3"
                      ></textarea>
                      <small className={descriptionCount >= 150 ? "text-danger" : "text-muted"}>
                        {descriptionCount}/156 caracteres
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <label>
                        H1: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        value={h1}
                        className="form-control"
                        required
                        placeholder="Ingresa el h1"
                        onChange={(e) => setH1(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>Productos</h2>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-sm-12 col-12 mb-4">
                      <label>
                        Buscar producto por SKU&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <div className="row">
                        <div className="col-12 d-flex align-self-center">
                          <input
                            type="text"
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                            onKeyUp={(e) => toUpperCase(e.target.value)}
                            className="form-control me-2"
                            required
                            placeholder="Ej: MON038"
                          />
                          <button
                            className="btnGreen w-100"
                            type="button"
                            onClick={handleSkuCode}
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </div>
                    {producto && (
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                        <p>
                          <strong>Producto encontrado:</strong>{" "}
                          {producto.nombre}
                        </p>
                        <button
                          className="btnGreen"
                          type="button"
                          onClick={handleAddProducto}
                        >
                          Agregar a la lista
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row my-3">
                    <div className="col-12 text-start mb-3">
                      <h2>Listado de Productos</h2>
                    </div>
                    <div className="col-12">
                      <ul className="list-group">
                        {productos.map((p) => (
                          <li
                            key={p.id}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <span>
                              {p.nombre} (SKU: {p.sku})
                            </span>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleRemoveProducto(p.id)}
                            >
                              Sacar de la lista
                            </button>
                          </li>
                        ))}
                        {productos.length === 0 && (
                          <li className="list-group-item">
                            No hay productos en la lista
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        onClick={handleSubmit}
                        aria-label="Crear campaña"
                        title="Crear campaña"
                        className="btnGreen w-100 mb-3"
                        type="button"
                      >
                        Crear campaña
                      </button>
                      <button
                        type="button"
                        aria-label="Volver"
                        title="Volver"
                        className="cancel w-100 mb-3"
                        onClick={() => {
                          handleVolver();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </div>
                </div>
                
              </>
            )}
            {accion == 2 && (
              <>
                {!busquedaCampana && (
                  <>
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>Buscar campaña</h2>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                          <button
                            className="agregar"
                            onClick={() => {
                              setBusquedaCampana(1);
                            }}
                          >
                            <i className="fa-solid fa-search"></i> &nbsp; Buscar
                            por ID
                          </button>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                          <button
                            className="agregar"
                            onClick={() => {
                              setBusquedaCampana(2);
                            }}
                          >
                            <i className="fa-solid fa-search"></i> &nbsp; Buscar
                            por fecha
                          </button>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                          <button
                            className="agregar"
                            onClick={() => {
                              setBusquedaCampana(3);
                            }}
                          >
                            <i className="fa-solid fa-search"></i> &nbsp; Buscar
                            por nombre
                          </button>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                          <button
                            type="button"
                            aria-label="Volver"
                            title="Volver"
                            className="cancel w-100 mb-3"
                            onClick={() => {
                              handleVolver();
                            }}
                          >
                            Volver
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {busquedaCampana == 1 && (
                  <>
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>Buscar campaña por ID</h2>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Ingresa el ID"
                        value={IdCampana}
                        onChange={(e) => {
                          setIdCampana(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 d-flex align-self-center">
                      <button
                        onClick={handleBotonBuscar}
                        aria-label="Buscar campaña por ID"
                        title="Buscar campaña por ID"
                        className="btnGreen w-50 mx-3"
                        type="button"
                      >
                        Buscar
                      </button>
                      <button
                        type="button"
                        aria-label="Volver"
                        title="Volver"
                        className="cancel w-50"
                        onClick={() => {
                          handleVolver();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </>
                )}
                {busquedaCampana == 2 && (
                  <>
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>Buscar campaña por Fecha</h2>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Ingresa la Fecha"
                        value={fechaCampana}
                        onChange={(e) => {
                          setFechaCampana(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 d-flex align-self-center">
                      <button
                        onClick={handleBotonBuscar}
                        aria-label="Buscar campaña por Fecha"
                        title="Buscar campaña por Fecha"
                        className="btnGreen w-50 mx-3"
                        type="button"
                      >
                        Buscar
                      </button>
                      <button
                        type="button"
                        aria-label="Volver"
                        title="Volver"
                        className="cancel w-50"
                        onClick={() => {
                          handleVolver();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </>
                )}

                {busquedaCampana == 3 && (
                  <>
                    <div className="col-12 text-start mt-3 mb-3">
                      <h2>Buscar campaña por Nombre</h2>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingresa el Nombre"
                        value={nombreCampana}
                        onChange={(e) => {
                          setNombreCampana(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 d-flex align-self-center">
                      <button
                        onClick={handleBotonBuscar}
                        aria-label="Buscar campaña por Nombre"
                        title="Buscar campaña por Nombre"
                        className="btnGreen w-50 mx-3"
                        type="button"
                      >
                        Buscar
                      </button>
                      <button
                        type="button"
                        aria-label="Volver"
                        title="Volver"
                        className="cancel w-50"
                        onClick={() => {
                          handleVolver();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </>
                )}

                <div>
                  {existenDatos && campanas.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table boder">
                          <thead className="p-4">
                            <tr>
                              <th scope="col">
                                <div className="id text-center">ID</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Nombre</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">
                                  Fecha de inicio
                                </div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">
                                  Fecha de termino
                                </div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">SEO</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Estado</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Acciones</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {campanas?.map((c) => (
                              <tr key={c.id}>
                                <th
                                  scope="row"
                                  style={{ alignContent: "center" }}
                                >
                                  <div className="number text-center">
                                    {c.id}
                                  </div>
                                </th>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.nombre}
                                  </div>
                                </td>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {formatFecha(c.fecha_inicio)}
                                  </div>
                                </td>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {formatFecha(c.fecha_termino)}
                                  </div>
                                </td>

                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.marcaje != null && (
                                      <>
                                        <span>
                                          Meta Title: {c.marcaje.title}
                                          <br></br>
                                        </span>
                                        <span>
                                          Meta Description:{" "}
                                          {c.marcaje.description}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </td>

                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.estado}
                                  </div>
                                </td>

                                <td className="align-self-center">
                                  <div className="nameNumber text-center d-flex">
                                    <button
                                      onClick={() => handleEstadoCampana(c)}
                                      class="agregar mx-2"
                                      title={
                                        c.activo == "1"
                                          ? "Anular campaña"
                                          : "Confirmar campaña"
                                      }
                                      aria-label={
                                        c.activo == "1"
                                          ? "Anular campaña"
                                          : "Confirmar campaña"
                                      }
                                    >
                                      {c.activo == "1"
                                        ? "Anular campaña"
                                        : "Confirmar campaña"}
                                    </button>
                                    <button
                                      class="agregar mx-2"
                                      title="Modificar"
                                      aria-label="Modificar"
                                      onClick={() => {
                                        handleModificar(c.id);
                                      }}
                                    >
                                      Modificar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row justify-content-end mt-3">
                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                          {currentPage > 1 && (
                            <button
                              type="button"
                              aria-label="Anterior"
                              className="agregar border-0 btnCelesteDreamtec"
                              onClick={() => setCurrentPage(currentPage - 1)}
                            >
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </div>

                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                          {currentPage + 1 <= totalPaginas && (
                            <button
                              type="button"
                              aria-label="Siguiente"
                              className="agregar border-0 btnCelesteDreamtec"
                              onClick={() => setCurrentPage(currentPage + 1)}
                            >
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {!existenDatos && (
                    <>
                      <div className="row my-5">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                          <i className="fa-solid fa-circle-info"></i>&nbsp;No se
                          encontraron campañas con el filtro actual
                        </div>
                      </div>
                    </>
                  )}

                  {/* Modal de confirmación */}
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                  >
                    <Modal.Header className="border-bottom-0" closeButton>
                      <Modal.Title>Confirmar acción</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Estás seguro de que deseas{" "}
                      {campanaSeleccionada?.activo == "1"
                        ? "anular"
                        : "confirmar"}{" "}
                      la campaña <strong>{campanaSeleccionada?.nombre}</strong>?
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                      <button
                        className="cancel mx-2"
                        onClick={() => setShowModal(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btnGreen mx-2"
                        onClick={confirmarCambioEstado}
                      >
                        Confirmar
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </>
            )}
            {accion == 3 && (
              <>
                <div className="col-12 text-start mt-3 mb-3">
                  <h2>Listar campañas</h2>
                </div>
                <div>
                  {campanas.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table boder">
                          <thead className="p-4">
                            <tr>
                              <th scope="col">
                                <div className="id text-center">ID</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Nombre</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">
                                  Fecha de inicio
                                </div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">
                                  Fecha de termino
                                </div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">SEO</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Estado</div>
                              </th>
                              <th scope="col">
                                <div className="id text-center">Acciones</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {campanas?.map((c) => (
                              <tr key={c.id}>
                                <th
                                  scope="row"
                                  style={{ alignContent: "center" }}
                                >
                                  <div className="number text-center">
                                    {c.id}
                                  </div>
                                </th>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.nombre}
                                  </div>
                                </td>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {formatFecha(c.fecha_inicio)}
                                  </div>
                                </td>
                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {formatFecha(c.fecha_termino)}
                                  </div>
                                </td>

                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.marcaje != null && (
                                      <>
                                        <span>
                                          Meta Title: {c.marcaje.title}
                                          <br></br>
                                        </span>
                                        <span>
                                          Meta Description:{" "}
                                          {c.marcaje.description}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </td>

                                <td style={{ alignContent: "center" }}>
                                  <div className="nameNumber text-center">
                                    {c.estado}
                                  </div>
                                </td>

                                <td className="align-self-center">
                                  <div className="nameNumber text-center d-flex">
                                    <button
                                      onClick={() => handleEstadoCampana(c)}
                                      class="agregar mx-2"
                                      title={
                                        c.activo == "1"
                                          ? "Anular campaña"
                                          : "Confirmar campaña"
                                      }
                                      aria-label={
                                        c.activo == "1"
                                          ? "Anular campaña"
                                          : "Confirmar campaña"
                                      }
                                    >
                                      {c.activo == "1"
                                        ? "Anular campaña"
                                        : "Confirmar campaña"}
                                    </button>
                                    <button
                                      class="agregar mx-2"
                                      title="Modificar"
                                      aria-label="Modificar"
                                      onClick={() => {
                                        handleModificar(c.id);
                                      }}
                                    >
                                      Modificar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row justify-content-end mt-3">
                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                          {currentPage > 1 && (
                            <button
                              type="button"
                              aria-label="Anterior"
                              className="agregar border-0 btnCelesteDreamtec"
                              onClick={() => setCurrentPage(currentPage - 1)}
                            >
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </div>

                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                          {currentPage + 1 <= totalPaginas && (
                            <button
                              type="button"
                              aria-label="Siguiente"
                              className="agregar border-0 btnCelesteDreamtec"
                              onClick={() => setCurrentPage(currentPage + 1)}
                            >
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row my-5">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                        <i className="fa-solid fa-circle-info"></i>&nbsp;No hay
                        campañas registradas
                      </div>
                    </div>
                  )}

                  {/* Modal de confirmación */}
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                  >
                    <Modal.Header className="border-bottom-0" closeButton>
                      <Modal.Title>Confirmar acción</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Estás seguro de que deseas{" "}
                      {campanaSeleccionada?.activo == "1"
                        ? "anular"
                        : "confirmar"}{" "}
                      la campaña <strong>{campanaSeleccionada?.nombre}</strong>?
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                      <button
                        className="cancel mx-2"
                        onClick={() => setShowModal(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btnGreen mx-2"
                        onClick={confirmarCambioEstado}
                      >
                        Confirmar
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <div className="row justify-content-end mt-3">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                      <button
                        type="button"
                        aria-label="Volver"
                        title="Volver"
                        className="cancel w-100 mb-3"
                        onClick={() => {
                          handleVolver();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campanas;
