import React, { useState, useRef } from "react";
import FormularioIndustrial from "../components/Industrial/formulario";
import FooterIndustrial from "../components/FooterIndustrial/footer";

const ImpresoraTextil = () => {
  const [productos, setProductos] = useState([]);
  const formularioRef = useRef(null);
  const selectRef = useRef(null);

  const productosInfo = [
    {
      id: 1,
      nombre: "Surecolor Ecotank F170",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_doce.jpg",
      link: "https://www.dreamtec.cl/impresora-epson-sublimacion-surecolor-tinta-f170-wifi",
    },
    {
      id: 2,
      nombre: "Surecolor F570 USB",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_trece.webp",
      link: "https://www.dreamtec.cl/impresora-plotter-para-sublimacion-epson-surecolor-f570",
    },
    {
      id: 3,
      nombre: "Brother GTX PRO",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_catorce.jpg",
      link: "https://www.dreamtec.cl/impresora-textil-brother-gtx-pro",
    },
  ];

  const handleCotizar = (producto) => {
    formularioRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      selectRef.current.focus();
    }, 500);
  };

  return (
    <>
      <div className="container-fluid mt-4 bgImpresora">
        <div className="row">
          <div className="col-md-12 text-white pt-5 text-center">
            <h1 className="pt-5 fw-bold h1">
              Impresión textil y de sublimación
            </h1>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container py-5 my-5">
          <div className="row justify-content-md-center">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-xxl-block d-xl-blocl d-lg-block d-md-none d-sm-none d-none text-center">
              <img
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/impresora_textil.png"}
                alt="persona configurando un plotter"
                className="img-fluid"
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <h1 className="h1">Transforma tus Diseños en Realidad</h1>
              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/brother.png"} alt="Logo Epson" className="marca mb-3" />
              <p className="p_industrial">
                Con nuestra máquina impresora textil GTX Pro de Brother. Con una
                combinación de precisión y versatilidad, la GTX Pro te permite
                imprimir diseños vibrantes y duraderos en una amplia variedad de
                tejidos. Ya sea que estés creando ropa personalizada, accesorios
                o productos promocionales, nuestra tecnología de impresión
                textil ayudará a destacarte en el mercado con productos de
                calidad superior.
              </p>
              <a
                href="/industrial/contacto"
                className="btn btn-primary_industrial"
              >
                CONTACTAR AHORA
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-md-center">
          {productosInfo.map((producto) => (
            <div
              key={producto.id}
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 border border-4 rounded-4 border-3 border-primary-subtle m-2 text-center pb-3"
            >
              <div className="border border-2 rounded-3 mt-4">
                <img
                  src={producto.img}
                  alt="Impresora Textil"
                  className="img-fluid"
                />
              </div>
              <h2 className="mb-3 fs-5 mt-3" style={{ color: "initial" }}>
                {producto.nombre}
              </h2>
              <a href={producto.link} className="btn btn-primary_industrial mb-2">
                VER PRODUCTO
              </a>
              <button
                type="button"
                aria-label="Cotizar Ahora"
                className="btn btn-secondary_industrial"
                onClick={() => handleCotizar(producto.nombre)}
              >
                COTIZAR AHORA
              </button>
            </div>
          ))}
        </div>
      </div>

      <div ref={formularioRef}>
        <FormularioIndustrial productos={productos} selectRef={selectRef} />
      </div>

      <FooterIndustrial />
    </>
  );
};

export default ImpresoraTextil;
