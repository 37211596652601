/**
 * Componente AdminSidebar:
 *
 * acá se muestra el menú lateral del admin
 *
 * @module ComponenteAdminSidebarjsx
 */

import { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./adminsidebar.css";

import { useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * @constant
   * @name rol
   * @type {string}
   *
   * */
  const rol = sessionStorage.getItem("rol");

  /**
   * @function
   * Petición HTTP para refrescar el token del acceso del admin logueado
   *
   */
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACK_URL + "/api/token/refresh", {
        refresh: sessionStorage.getItem("refresh"),
      })
      .then((response) => {
        sessionStorage.setItem("refresh", response.data.refresh);
      })
      .catch((error) => {
        console.log(error);
      });

    if (!sessionStorage.getItem("access")) {
      navigate("/admin/login");
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="mt-3 ms-3 col-offset-1">
          <button
            className="btnCelesteDreamtec rounded-pill fs-12"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start bg-transparent border-0"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <div className="menuVertical m-0 p-0">
            <img
              loading="lazy"
              src={
                process.env.REACT_APP_FRONT_URL +
                "/uploads/assets/admin/logo-header-blanco.png"
              }
              alt="Logo Dreamtec"
              title="Logo Dreamtec"
              className="logoAdminMenu mb-2 my-3"
            />

            <div className="row m-0 p-0 justify-content-end mb-4">
              <div
                className="mt-3 ms-3 col-offset-1 fondo-dreamtec rounded-pill"
                role="button"
                data-bs-dismiss="offcanvas"
                style={{ fontSize: "10px", color: "white" }}
              >
                <i className="fa-solid fa-arrow-left"></i> &nbsp;Cerrar
                Navegación
              </div>
            </div>
            <ul className="nav nav-pills flex-column mb-auto  mem">
              {/* Pedidos Web */}
              {rol >= 1 && rol <= 3 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidos"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidos" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-gift  mem"></i>
                    <span className="text-white">Pedidos</span>
                  </Link>
                </li>
              )}

              {/* Pedidos PDV */}
              {rol >= 1 && rol <= 3 && (
                <li className="nav-item">
                  <Link
                    to="/admin/puntoventa"
                    className={`nav-link ${
                      location.pathname === "/admin/puntoventa" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-desktop  mem"></i>
                    <span className="text-white">Punto de venta</span>
                  </Link>
                </li>
              )}

              {/* Pedidos por Chofer */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidosasignados"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidosasignados"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-truck  mem"></i>
                    <span className="text-white">Pedidos por chofer</span>
                  </Link>
                </li>
              )}

              {/* Productos */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/productos"
                    className={`nav-link ${
                      location.pathname === "/admin/productos" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-brands fa-product-hunt  mem"></i>
                    <span className="text-white">Productos</span>
                  </Link>
                </li>
              )}

              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/categorias"
                    className={`nav-link ${
                      location.pathname === "/admin/categorias" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-circle-plus  mem"></i>
                    <span className="text-white">Categorías</span>
                  </Link>
                </li>
              )}

              {/* Grandes Marcas */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/grandesmarcas"
                    className={`nav-link ${
                      location.pathname === "/admin/grandesmarcas"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-copyright  mem"></i>
                    <span className="text-white">Grandes marcas</span>
                  </Link>
                </li>
              )}

              {/* Marcas */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/marcas"
                    className={`nav-link ${
                      location.pathname === "/admin/marcas" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">Marcas</span>
                  </Link>
                </li>
              )}

              {/* Marcaje */}
              {(rol == 1 || rol == 6) && (
                <li className="nav-item">
                  <Link
                    to="/admin/marcaje"
                    className={`nav-link ${
                      location.pathname === "/admin/marcaje" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">SEO</span>
                  </Link>
                </li>
              )}

              {/* Precampana */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/precampana"
                    className={`nav-link ${
                      location.pathname === "/admin/precampana" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">Precampaña</span>
                  </Link>
                </li>
              )}

              {/* Marcaje */}
              {(rol == 1 || rol == 6) && (
                <li className="nav-item">
                  <Link
                    to="/admin/marcaje-banners"
                    className={`nav-link ${
                      location.pathname === "/admin/marcaje-banners"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">SEO Banners</span>
                  </Link>
                </li>
              )}

              {/* Marcaje */}
              {(rol == 1 || rol == 6) && (
                <li className="nav-item">
                  <Link
                    to="/admin/marcaje-banner-categoria"
                    className={`nav-link ${
                      location.pathname === "/admin/marcaje-banner-categoria"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">SEO Banners Categoria</span>
                  </Link>
                </li>
              )}

              {/* Campaña */}
              { (rol == 1) && (
                <li className="nav-item">
                  <Link
                    to="/admin/campana"
                    className={`nav-link ${
                      location.pathname === "/admin/campana" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-registered  mem"></i>
                    <span className="text-white">Campañas</span>
                  </Link>
                </li>
              )}

              {/* Banner Slider */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/banners"
                    className={`nav-link ${
                      location.pathname === "/admin/banners" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-flag  mem"></i>
                    <span className="text-white">Banner Slider</span>
                  </Link>
                </li>
              )}

              {/* Banners de categorías */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/bannercategorias"
                    className={`nav-link ${
                      location.pathname === "/admin/bannercategorias"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-house  mem"></i>
                    <span className="text-white">Banner de home</span>
                  </Link>
                </li>
              )}

              {/* Usuarios (web y pos) */}
              {rol >= 1 && rol <= 3 && (
                <li className="nav-item">
                  <Link
                    to="/admin/usuarios"
                    className={`nav-link ${
                      location.pathname === "/admin/usuarios" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-users  mem"></i>
                    <span className="text-white">Usuarios</span>
                  </Link>
                </li>
              )}

              {/* Personal (Listar y agregar -> super admin) */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/personal"
                    className={`nav-link ${
                      location.pathname === "/admin/personal" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-user-tie mmem"></i>
                    <span className="text-white">Personal</span>
                  </Link>
                </li>
              )}

              {/* Personal (Solo listar -> admin) */}
              {rol == 2 && (
                <li className="nav-item">
                  <Link
                    to="/admin/personaladmin"
                    className={`nav-link ${
                      location.pathname === "/admin/personaladmin"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-user-tie mmem"></i>
                    <span className="text-white">Personal</span>
                  </Link>
                </li>
              )}

              {/* Tarifas */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/tarifas"
                    className={`nav-link ${
                      location.pathname === "/admin/tarifas" ? "active" : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-coins  mmem"></i>
                    <span className="text-white">Tarifas de despacho</span>
                  </Link>
                </li>
              )}

              {/* Log Transacciones */}
              {rol == 1 && (
                <li className="nav-item">
                  <Link
                    to="/admin/transacciones"
                    className={`nav-link ${
                      location.pathname === "/admin/transacciones"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-clipboard-list mmem"></i>
                    <span className="text-white">Log de Transacciones</span>
                  </Link>
                </li>
              )}

              {/* Pedidos por chofer web (chofer) */}
              {rol == 4 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidoschofer"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidoschofer"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-truck  mem"></i>
                    <span className="text-white">Pedidos Web</span>
                  </Link>
                </li>
              )}

              {/* Pedidos por chofer pdv (chofer) */}
              {rol == 4 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidoschoferpdv"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidoschoferpdv"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-truck  mem"></i>
                    <span className="text-white">Pedidos Punto de Venta</span>
                  </Link>
                </li>
              )}

              {/* Pedidos Web (Bodega) */}
              {rol == 5 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidosbodegaweb"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidosbodegaweb"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-earth-americas  mem"></i>
                    <span className="text-white">Pedidos Web</span>
                  </Link>
                </li>
              )}

              {/* Pedidos PDV (bodega) */}
              {rol == 5 && (
                <li className="nav-item">
                  <Link
                    to="/admin/pedidosbodegapdv"
                    className={`nav-link ${
                      location.pathname === "/admin/pedidosbodegapdv"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="text-white mx-2 fa-solid fa-desktop  mem"></i>
                    <span className="text-white">Pedidos Punto de Venta</span>
                  </Link>
                </li>
              )}
            </ul>

            <hr className="mt-5" />
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <a
                onClick={() => {
                  navigate("/admin/login");
                  sessionStorage.removeItem("access");
                  sessionStorage.removeItem("refresh");
                  sessionStorage.removeItem("rol");
                }}
                className="cursor-pointer text-decoration-none text-white btn-cerrar-sesion-nav"
              >
                Cerrar Sesión
                <svg
                  className="m-1 svg-inline--fa fa-right-from-bracket"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="right-from-bracket"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg
                  width={12}
                  height={12}
                >
                  <path
                    fill="currentColor"
                    d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
