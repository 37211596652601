import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Suspense, lazy } from "react";
import axios from "axios";
import Home from "../pages/Home";
import Campanas from "../pages/admin/Campanas";

const ModificarPrecampanaAdmin = lazy(() => import("../pages/admin/ModificarPrecampana"));
const PrecampanaAdmin = lazy(() => import("../pages/admin/Precampana"));
const PrecampanaCliente = lazy(() => import("../pages/Precampana"));
const MarcajeBanners = lazy(() => import("../pages/admin/MarcajeBanners"));
const MarcajeBannersCategorias = lazy(() =>
  import("../pages/admin/MarcajeBannersCategorias")
);
const Marcaje = lazy(() => import("../pages/admin/Marcaje"));
const AdminLogin = lazy(() => import("../pages/admin/AdminLogin"));
const Banners = lazy(() => import("../pages/admin/Banners"));
const BannersCategorias = lazy(() =>
  import("../pages/admin/BannersCategorias")
);
const EditarCampana = lazy(() => import("../pages/admin/EditarCampana"));
const Cart = lazy(() => import("../pages/Cart"));
const Category = lazy(() => import("../pages/Category"));
const CategoryPage = lazy(() => import("../pages/CategoryPage"));
const CategoriasCRUD = lazy(() => import("../pages/admin/CategoriasCRUD"));
const Checkout = lazy(() => import("../pages/Checkout"));
const ComprasAnteriores = lazy(() => import("../pages/ComprasAnteriores"));
const Confirmed = lazy(() => import("../pages/Confirmed"));
const Contacto = lazy(() => import("../pages/Contacto"));
const ContactoIndustrial = lazy(() => import("../pages/ContactoIndustrial"));
const DashBoard = lazy(() => import("../pages/admin/DashBoard"));
const Garantias = lazy(() => import("../pages/footer/Garantias"));
const GrandesMarcas = lazy(() => import("../pages/admin/GrandesMarcas"));
const ImpresoraTextil = lazy(() => import("../pages/ImpresoraTextil"));
const Industrial = lazy(() => import("../pages/Industrial"));
const LoUltimoEn = lazy(() => import("../pages/LoUltimoEn"));
const LogTransacciones = lazy(() => import("../pages/admin/LogTransacciones"));
const Mantencion = lazy(() => import("../pages/Mantencion"));
const MaquinasBordar = lazy(() => import("../pages/MaquinasBordar"));
const Marcas = lazy(() => import("../pages/admin/Marcas"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Paso1 = lazy(() => import("../pages/Paso1"));
const Paso2 = lazy(() => import("../pages/Paso2"));
const Paso3 = lazy(() => import("../pages/Paso3"));
const Pedidos = lazy(() => import("../pages/admin/Pedidos"));
const PedidosAsignados = lazy(() => import("../pages/admin/PedidosAsignados"));
const PedidosBodegaPDV = lazy(() => import("../pages/admin/PedidosBodegaPDV"));
const PedidosBodegaWeb = lazy(() => import("../pages/admin/PedidosBodegaWeb"));
const PedidosChofer = lazy(() => import("../pages/admin/PedidosChofer"));
const PedidosChoferPDV = lazy(() => import("../pages/admin/PedidosChoferPDV"));
const Personal = lazy(() => import("../pages/admin/Personal"));
const PersonalAdmin = lazy(() => import("../pages/admin/PersonalAdmin"));
const Perfil = lazy(() => import("../pages/Perfil"));
const Plotter = lazy(() => import("../pages/Plotter"));
const Preguntas = lazy(() => import("../pages/footer/Preguntas"));
const ProductAdd = lazy(() => import("../pages/admin/ProductAdd"));
const ProductUpdate = lazy(() => import("../pages/admin/ProductUpdate"));
const Productos = lazy(() => import("../pages/admin/Productos"));
const PuntoVenta = lazy(() => import("../pages/admin/PuntoVenta"));
const Search = lazy(() => import("../pages/Search"));
const Shop = lazy(() => import("../pages/Shop"));
const Tarifas = lazy(() => import("../pages/admin/Tarifas"));
const Terminas = lazy(() => import("../pages/footer/Terminas"));
const Usuarios = lazy(() => import("../pages/admin/Usuarios"));
const CampaignMaster = lazy(() => import("../pages/CampaignMaster"));

const Routers = () => {
  const [categoriaState, setCategoriaState] = useState("");
  const esCategoria = useSelector((state) => state.booleanCategoria);
  const esBusqueda = useSelector((state) => state.boolean);
  const redirect = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname !== "/") {
      const regex = /\/(.+)/;
      const match = regex.exec(pathname);
      const categoria = match[1];
      setCategoriaState(categoria);
    }
  }, [esCategoria, esBusqueda, categoriaState]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/mantencion/checkmantencion`)
      .then((res) => {
        if (
          res.data.ip ===
          "G7WX3nx1vJ.hGSMgVSdYEu0CDu0x6B3jgTd2xvjtiQVGOZIshPDYm"
        )
          navigate("/mantencion");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [redirect]);

  return (
    <Suspense
      fallback={
        <div>
          <div className="container py-5 my-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="fw-bold fs-1 mb-2 text-dark">Cargando</h1>
                <div
                  className="spinner-border color-35 spinner-detail"
                  role="status"
                >
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/industrial" element={<Industrial />} />
        <Route path="/industrial/plotter" element={<Plotter />} />
        <Route
          path="/industrial/maquinas-bordar"
          element={<MaquinasBordar />}
        />
        <Route
          path="/industrial/impresora-textil"
          element={<ImpresoraTextil />}
        />
        <Route path="/industrial/contacto" element={<ContactoIndustrial />} />
        <Route path="/" element={<Home />} />
        <Route path="/:Slug" element={<Category />} />
        <Route path="/:Padre/:Hija" element={<CategoryPage />} />
        <Route path="/loultimoen/:Hija" element={<LoUltimoEn />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/search" element={<Search />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/compras" element={<ComprasAnteriores />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/confirmed" element={<Confirmed />} />
        <Route path="/paso1" element={<Paso1 />} />
        <Route path="/paso2" element={<Paso2 />} />
        <Route path="/paso3" element={<Paso3 />} />
        <Route path="/mantencion" element={<Mantencion />} />
        <Route path="/ofertas-tecnologia" element={<CampaignMaster />} />
        {/* Rutas footer */}
        <Route path="/terminos" element={<Terminas />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/garantias" element={<Garantias />} />
        <Route path="/faq" element={<Preguntas />} />
        {/* Rutas de administrador */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<DashBoard />} />
        <Route path="/admin/tarifas" element={<Tarifas />} />
        <Route path="/admin/usuarios" element={<Usuarios />} />
        <Route path="/admin/puntoventa" element={<PuntoVenta />} />
        <Route path="/admin/pedidos" element={<Pedidos />} />
        <Route path="/admin/personal" element={<Personal />} />
        <Route path="/admin/productos" element={<Productos />} />
        <Route path="/admin/productos/agregar" element={<ProductAdd />} />
        <Route path="/admin/productos/actualizar" element={<ProductUpdate />} />
        <Route path="/admin/banners" element={<Banners />} />
        <Route path="/admin/grandesmarcas" element={<GrandesMarcas />} />
        <Route path="/admin/bannercategorias" element={<BannersCategorias />} />
        <Route path="/admin/pedidosbodegaweb" element={<PedidosBodegaWeb />} />
        <Route path="/admin/pedidoschofer" element={<PedidosChofer />} />
        <Route path="/admin/pedidoschoferpdv" element={<PedidosChoferPDV />} />
        <Route path="/admin/pedidosasignados" element={<PedidosAsignados />} />
        <Route path="/admin/personaladmin" element={<PersonalAdmin />} />
        <Route path="/admin/marcas" element={<Marcas />} />
        <Route path="/admin/categorias" element={<CategoriasCRUD />} />
        <Route path="/admin/pedidosbodegapdv" element={<PedidosBodegaPDV />} />
        <Route path="/admin/transacciones" element={<LogTransacciones />} />
        <Route path="/admin/marcaje" element={<Marcaje />} />
        <Route path="/admin/marcaje-banners" element={<MarcajeBanners />} />
        <Route path="/admin/precampana" element={<PrecampanaAdmin />} />
        <Route path="/admin/campana" element={<Campanas />} />
        <Route path="/admin/editar-campana" element={<EditarCampana />} />
        <Route path="/admin/modificar-precampana" element={<ModificarPrecampanaAdmin />} />
        <Route
          path="/admin/marcaje-banner-categoria"
          element={<MarcajeBannersCategorias />}
        />
        {/* Ruta de 404 */}
        <Route path="*" element={<NotFound />} status={404} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
